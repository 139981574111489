import React from 'react';

import Layout from '../components/layout';

export default function Home() {
  return (
    <Layout
      title="Contact Us"
      mainClasses="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24"
    >
      <div className="relative max-w-xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Contact us
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            We can't wait to get to know your agency!
          </p>
        </div>
        <div className="mt-12">
          <form
            action="https://formspree.io/f/mzbkyzoe"
            method="POST"
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                First name
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="first_name"
                  name="first_name"
                  required
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Last name
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="last_name"
                  name="last_name"
                  required
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Agency/Company
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="company"
                  name="company"
                  required
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Email
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="phone_number"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="phone_number"
                  name="phone_number"
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                  placeholder="(555) 987-6543"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Message
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150"
                ></textarea>
              </div>
            </div>
            <div className="sm:col-span-2">
              <span className="w-full inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brandblue hover:bg-brandlightblue focus:outline-none focus:border-brandbg focus:shadow-outline-brandgreen active:bg-brandlightblue-700 transition ease-in-out duration-150"
                >
                  Let's talk
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
